<template>
  <div class="information">
    <div v-if="list && list.length">
      <el-row :gutter="20">
        <el-col :span="6" v-for="item in list" :key="item.id" class="list">
          <div class="imgPanel" @click="gotoItem(item)">
            <img :src="item.coverImage" alt="" class="listImg" />
            <img src="../../../assets/images/video.png" alt="" class="video" v-if="item.type == 2" />
          </div>
          <div style="min-height: 50px;">
            <div class="title">{{item.title}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else class="empty">
      暂无数据
    </div>
    <div class="btn_more" v-if="list && list.length">
      <div class="btn" @click="getMore()">
        <span>{{moreTxt}}</span>
        <span class="icon-jiazaigengduo1 iconfont"></span>
      </div>
    </div>
  </div>
</template>

<script>
import getAxios from "../../../request";
export default {
  data() {
    return {
      params: {
        current: 1,
        size: 12,
      },
      list: [],
      moreTxt:'加载更多',
      isMore:true,
    }
  },
  methods: {
    getPage() {
      let that = this
      getAxios({
        url:"/fedexCup/list",
        data: this.params,
      }).then(response => {
        console.log(response);
        that.list = that.list.concat(response.records);
        if (response.records.length < 12) {
          that.moreTxt = '暂无更多数据'
        }
      }).catch(() => {

      })
    },
    getMore(){
      this.params.current++
      this.getPage();
    },
    gotoItem(item) {
      if(item.type == 1) {
        // 跳转文章
        this.$router.push({
          path: '/NewsDetails',
          query: { id: item.contentId }
        })
      } else if(item.type == 2) {
        // 跳转视频
        this.$router.push({
          path: '/Video',
          query: { id: item.contentId }
        })
      }
    },
  },
  mounted() {
    this.getPage();
  },
}
</script>

<style lang="less" scoped>
  .list {
    margin-bottom: 40px;
    .imgPanel {
      width: 300px;
      height: 168px;
      position: relative;
      cursor: pointer;
      .listImg {
        width: 100%;
        height: 100%;
        background-image: none;
      }
      .video {
        position: absolute;
        background-image: none;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .title {
      // font-weight: bold;
      margin-top: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      width: 300px;
      word-wrap: break-word;
    }
  }
  .information {
    position: relative;
  }
  .btn_more {
    position: absolute;
    background: #DDDDDD;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    .btn {
      cursor: pointer;
      background-color: #ffffff;
      padding-top: 10px;
      font-size: 17px;
      text-align: center;
      color: #385570;
      padding-bottom: 10px;
      padding-left: 100px;
      span {
        font-weight: 900;
      }
      .icon-jiazaigengduo1 {
        margin-left: 40px;
        margin-right: 50px;
      }
    }
  }
  .empty {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
</style>